import { CardEvent } from "./cardEvent"


export const Events = ({data}) => {
    console.log(data);

    return (
        <div id='events' className='text-center'>
            <div className='container'>
                <div className='section-title'>
                    <h2>Расписание соревнований</h2>
                </div>
                <div className='events'>
                    <img src="img/events_28122024.jpg" />
                    {/* <button><a href="https://tsentr-razvlecheniy-yes.timepad.ru/event/2747661/">Регистрация на 03.02</a></button> */}
                </div>
            </div>
        </div>
    )
}